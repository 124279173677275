import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { mockData } from 'src/app/mock-data/incident-table-data';
import { Subscription, BehaviorSubject, scan, Observable, take, of } from 'rxjs';
import { defaultReqDriverTripDetails, generateTripDetailsOptions, IReqDriverTripDetails, IResDriverTripDetails, withLocationsAndViolations } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-trip-details';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import { LightmetricsService } from 'src/app/providers/services/lightmetrics-service';
import { ActivatedRoute } from '@angular/router';
import { IPolyIncidentTableResult, IPolyRoute, IPolyRouteEvent, IPolyStops, IPolyTrip, IPolyTripHeaderData, IPolyViolation } from 'src/app/concepts/trips/trip';
import { EIncidentType, ETripEventType, remapTypeIn } from 'src/app/concepts/incidents/incident-types';
import { getMapMarkerForEvent } from 'src/app/concepts/assets/map-markers';
import moment from 'moment';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { IResDriverEventDetails } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-event-details';
import { EIncidentSeverity } from 'src/app/concepts/incidents/incident-severity';
import { TranslateService } from '@ngx-translate/core';
import { FleetService } from 'src/app/providers/services/fleet-service';
import { UserService } from 'src/app/providers/services/user-service';
import { TripDetailsStopsTableComponent } from 'src/app/components/trips/trip-details-stops-table/trip-details-stops-table.component';

@Component({
  selector: 'vex-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss']
})
export class TripDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('map', { static: false }) map: GoogleMap

  @ViewChild('stopsMap', { static: false }) stopsMap: GoogleMap

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow

  @ViewChild('stopsTable') stopsTable: TripDetailsStopsTableComponent ;

  public lat: number = 51.678418;
  public lng: number = 7.809007;
  public bounds: google.maps.LatLngBounds
  public tripDetailsData: IResDriverTripDetails
  public eventDetailsData: IResDriverEventDetails[] = []
  public mapEventsData: IPolyRouteEvent[]
  public stopsMapEventsData: IPolyRouteEvent[]
  private _subs: Subscription[] = []
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  private _numResourcesToLoad: number = 1
  private _numEventsToLoad: number = 0
  private _numEventsLoaded: number = 0
  public polyTrip$: Observable<IPolyTrip>
  public polyMarkers$: Observable<IPolyRouteEvent[]>
  public stopsPolyMarkers$: Observable<any[]>
  public polyHeader$: Observable<IPolyTripHeaderData>
  public polyTableData$: Observable<IPolyIncidentTableResult[]>
  public tableDataLoaded$: Observable<boolean>
  private _rawPolyTableData: IPolyIncidentTableResult[]
  public mapMarkers: google.maps.Marker[] = [];
  public infoContent: string = 'Hello'
  // we want to be able to pass extra data to this, or maybe not...
  // maybe we can just deeply style it...

  // public tableColumns: TableColumn<IIncidentTableResult>[] = [
  //   {
  //     label: this.translate.instant('tripDetails.incident'),
  //     property: 'incident',
  //     type: 'incident'
  //   },
  //   {
  //     label: this.translate.instant('tripDetails.actions'),
  //     property: 'action',
  //     type: 'actions',
  //   }
  // ];
  public tableData = mockData;

  public driverId: string
  public tripId: string
  public language: string = this.translate.currentLang
  private user: string
  private _fleetType: boolean;
  private userFleetId: string
  private _stopthreshold: number;

  private _stopsDataSource:  IPolyStops[] = []; 
  public stopsPolyTableData$: Observable<any[]>
  public stopsTableDataLoaded$: Observable<boolean>

  public selectedOption: string;

  dropdownOptions = [
    { label: this.translate.instant('stopsTable.all'), value: '30000' },
    { label: '1 ' + this.translate.instant('stopsTable.minute'), value: '60000' },
    { label: '1.5 ' + this.translate.instant('stopsTable.minute'), value: '90000' },
    { label: '2 ' + this.translate.instant('stopsTable.minutes'), value: '120000' },
    { label: '2.5 ' + this.translate.instant('stopsTable.minutes'), value: '150000' },
    { label: '3 ' + this.translate.instant('stopsTable.minutes'), value: '180000' },
    { label: '3.5 ' + this.translate.instant('stopsTable.minutes'), value: '210000' },
    { label: '4 ' + this.translate.instant('stopsTable.minutes'), value: '240000' },
    { label: '4.5 ' + this.translate.instant('stopsTable.minutes'), value: '270000' },
    { label: '5 ' + this.translate.instant('stopsTable.minutes'), value: '300000' }
];

  constructor(
    public fdl: FleetDataLayer,
    public lms: LightmetricsService,
    private _activatedroute: ActivatedRoute,
    private translate: TranslateService,
    private fleetService: FleetService,
    private userService: UserService
  ) {
    this.driverId = this._activatedroute.snapshot.paramMap.get("driverId");
    this.tripId = this._activatedroute.snapshot.paramMap.get("tripId");
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
    this.selectedOption = this.dropdownOptions[0].value;
  }

  // todo - add context to select driver trips or ongoing trips

  private tripDetailsDatasource = ((options: IReqDriverTripDetails, driverId: string, tripId: string) : Observable<IResDriverTripDetails> => {
    return this.fdl.getDriverTripDetails(this.driverId, this.tripId, generateTripDetailsOptions(options))
  });

  // private tripDetailsDatasourceV2 = ((options: IReqDriverTripDetails, driverId: string, tripId: string) : Observable<IResDriverTripDetails> => {
  //   return this.fdl.geTripDetailsV2(this.tripId, generateTripDetailsOptions(options))
  // });

  // private eventDetailsDatasource = ((options: IReqDriverEventDetails, driverId: string, tripId: string, eventIndex: number) : Observable<IResDriverEventDetails> => {
  //   return this.fdl.getDriverEventDetails(this.driverId, this.tripId, eventIndex, generateEventDetailsOptions(options))
  // });

  // private ongoingTripsDatasource = ((options: IReqOngoingTrips) : Observable<IResOngoingTripsRow[]> => {
  //   return this.fdl.getOngoingTrips(generateOngoingTripsOptions(options))
  // });

  async ngOnInit(): Promise<void> {
    this.getUser();
    const user = await this.userService.getPortalUser(this.user);
    this.userFleetId = user.fleetId;
    this._fleetType = this.fleetService.isVikingFleet(this.userFleetId);
    console.log('fleet type', this._fleetType);
    this._runQuery()
  }

  public getUser(){
    const localStorageKeys = Object.keys(localStorage);
    console.log('Local storage keys:', localStorageKeys);
    let lastAuthUser = null; 
    for (let i = 0; i < localStorageKeys.length; i++) {
      const key = localStorageKeys[i];
      if (key.endsWith('LastAuthUser')) {
        lastAuthUser = localStorage.getItem(key); 
        break;
      }
    }
    if (lastAuthUser) {
      console.log('Last authenticated user:', lastAuthUser);
      this.user = lastAuthUser
    }
    else
    { console.log('No last authenticated user found in local storage.'); }
  }

  private _runQuery(): void {
    this.tableDataLoaded$ = of(false)
    this._numResourcesToLoad = 1

    if(this._subs?.length > 0) {
      this._subs.forEach(sub => sub.unsubscribe())
      this._subs = []
    }
    withLocationsAndViolations.stopThreshold = this._stopthreshold


    this._increment = new BehaviorSubject<number>(0);
    this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    this._subs.push(this.loaded.subscribe(loadedCounter => {
      if(loadedCounter === this._numResourcesToLoad) {
        // if(this._numEventsToLoad > this._numEventsLoaded) {
        //   this._numResourcesToLoad++
        //   this._loadNextEvent()
        // } else {
          this._increment.complete()
          if(this._fleetType){
            this.extractPageDataV2()
          }else{
            this.extractPageData()
          }
        // }
      }
    }))

    if(this._fleetType){
      this.fdl.geTripDetailsV2(this.tripId,this.userFleetId).pipe(take(1)).subscribe({
        next: res => {
          this.tripDetailsData = res.data
          // TODO - No, here we kick off all the requests for extra event data, THEN we call extractPageData
          // Question is, how many can we run in parallel? Answer the browser will queue them, do em all!
  
          // if(res?.violations?.length > 0) {
  
            // this.eventDetailsData = []
  
            // this._numEventsToLoad = res.violations.length
            // this._numEventsLoaded = 0
  
            this._increment.next(1)
          // }
        },
        error: err => {
          console.warn('Error getting trip details...', err)
        }
      })
    }else{
      this.fdl.addCache<IResDriverTripDetails>(this.tripId, this.tripDetailsDatasource, {
        ...defaultReqDriverTripDetails,
        ...withLocationsAndViolations
      }, true)
  
      
  
      this.fdl.getCacheType<IResDriverTripDetails>(this.tripId).getResult([this.driverId, this.tripId]).pipe(take(1)).subscribe({
        next: res => {
          console.log('Trip Details', res)
          this.tripDetailsData = res
          // TODO - No, here we kick off all the requests for extra event data, THEN we call extractPageData
          // Question is, how many can we run in parallel? Answer the browser will queue them, do em all!
  
          // if(res?.violations?.length > 0) {
  
            // this.eventDetailsData = []
  
            // this._numEventsToLoad = res.violations.length
            // this._numEventsLoaded = 0
  
            this._increment.next(1)
          // }
        },
        error: err => {
          console.warn('Error getting trip details...', err)
        }
      })
    }
  }

  stopLocationPan( eventData: any): void {
    this.stopsMap.panTo({lat: eventData.tableRow.location.latitude, lng: eventData.tableRow.location.longitude}) 
    this.stopsMap.googleMap.setZoom(20)
  }

  onSelectionChange(selectedOption: string) {
    this._stopthreshold = parseInt(selectedOption);
    this.stopsTableDataLoaded$ = of(false)
    this._runQuery()
}
  
  ngAfterViewInit() {
  }

  // private _loadNextEvent() {
  //   let count = 0
  //   const event = this.tripDetailsData.violations[this._numEventsLoaded]
  //   console.log('_loadNextEvent - eventIndex:', event.eventIndex)
  //   this.fdl.addCache<IResDriverEventDetails>('eventDetails', this.eventDetailsDatasource, {
  //     ...defaultReqDriverEventDetails
  //   }, true)

  //   this.fdl.getCacheType<IResDriverEventDetails>('eventDetails').getResult([this.driverId, this.tripId, event.eventIndex]).pipe(take(1)).subscribe({
  //     next: details => {
  //       console.log('successfully received the event', event.eventIndex)
  //       this.eventDetailsData.push(details)
  //       this._numEventsLoaded++
  //       this._increment.next(1)
  //     },
  //     error: err => {
  //       console.warn('Error fetching (', count, ')', err)
  //     }
  //   })
  // }

  onIncidentTypeFilterChange(filter: string): void {
    this.polyTableData$ = of(filter === this.translate.instant('tripDetails.all')
      ? this._rawPolyTableData
      : this._rawPolyTableData.filter(row => row.type === filter)
    )
    this.polyMarkers$ = of(filter === this.translate.instant('tripDetails.all')
      ? this.mapEventsData
      : this.mapEventsData.filter(marker => {
        return marker.meta.type === 'ignition' ||
          (marker.meta.type === 'violation' && filter === marker.meta.severity)
      })
    )
  }

  getSeverity(eventIndex: number) : EIncidentSeverity {
    const details = this.eventDetailsData.find(e => e.eventIndex === eventIndex)
    const severityIndex = Math.round(((details?.severity || 0) * 100) / 33.3333333)
    return severityIndex
  }

  extractPageData(): void {

    const severityTypes = ['low', 'medium' , 'high']


    const obj = this.tripDetailsData.pathInfo?.reduce((acc, location) => {
      if(!(location.latitude === 0 && location.longitude === 0)) {
        acc.route.locationData.push({
          lat: location.latitude,
          lng: location.longitude
        })
        acc.routeTimes.push(location.timestampUTC.toString())
      }
      return acc
    }, {route: {locationData: []}, routeTimes: []})
    const route:IPolyRoute = obj?.route
    const routeTimes = obj?.routeTimes || []

    const violations = this.tripDetailsData?.violations
      ? this.tripDetailsData?.violations.map<IPolyViolation>(v => {
        return {
          type: EIncidentType[remapTypeIn(v.eventType)],
          location: {
            lat: v.latitude,
            lng: v.longitude,
          },
          time: v.timestamp,
          severity: this.getSeverity(v.eventIndex)
        }}).sort((a,b) => b.time.localeCompare(a.time))
      : null

      const stops = this.tripDetailsData?.stops
      ? this.tripDetailsData?.stops.map<IPolyStops>(v => {
        return {
          startTime: v.startTime,
          endTime: v.endTime,
          duration: v.duration,
          location: {
            latitude:  v.location.latitude,
            longitude: v.location.longitude,
            bearing:   v.location.bearing,
            speed:     v.location.speed,
            accuracy:  v.location.accuracy,
          }
        }}).sort((a,b) => b.startTime - a.startTime)
      : null

      this._stopsDataSource = stops;

      this.stopsPolyTableData$ = of(this._stopsDataSource)
      this.stopsTableDataLoaded$ = of(true)

    //console.log('*TD: violations count', violations?.length || 0)
    //console.log('*TD: PathInfo count', this.tripDetailsData.pathInfo?.length || 0)
    const trip: IPolyTrip = {
      tripId: this.tripDetailsData.tripId,
      driverId: this.tripDetailsData.driverId,
      driverName: this.tripDetailsData.driverName,
      tripStart: this.tripDetailsData.startTime,
      tripEnd: this.tripDetailsData.startTime,
      route,
      routeTimes,
      violations
    }

    const tripStartEvent = this.tripDetailsData?.pathInfo ? {
      position: {
        lat: this.tripDetailsData.pathInfo[0].latitude,
        lng: this.tripDetailsData.pathInfo[0].longitude
      },
      options: {
        draggable: false,
        icon: getMapMarkerForEvent(ETripEventType['Trip-Start'])//?.iconURI,
      },
      meta: {
        type: 'ignition',
        action: 'on'
      }
    } : null

    const tripEndEvent = this.tripDetailsData?.pathInfo ? {
      position: {
        lat: this.tripDetailsData.pathInfo[this.tripDetailsData.pathInfo.length-1].latitude,
        lng: this.tripDetailsData.pathInfo[this.tripDetailsData.pathInfo.length-1].longitude
      },
      options: {
        draggable: false,
        icon: getMapMarkerForEvent(ETripEventType['Trip-Finish'])//?.iconURI,
      },
      meta: {
        type: 'ignition',
        action: 'off'
      }
    } : null

    const tripEvents: IPolyRouteEvent[] = this.tripDetailsData.violations.map<IPolyRouteEvent>(v => {
      console.log('v.eventType', this.translate.instant(EIncidentType[remapTypeIn(v.eventType)]))
      v.eventType = remapTypeIn(v.eventType)
      const pre = {
        position: {
          lat: v.latitude,
          lng: v.longitude
        },
        options: {
          draggable: false,
          icon: getMapMarkerForEvent( EIncidentType[remapTypeIn(v.eventType)], this.getSeverity(v.eventIndex)),//?.iconURI,
        },
        meta: {
          type: 'violation',
          violation: v,
          severity: severityTypes[this.getSeverity(v.eventIndex)]
        }
      }
      return pre
    })

    const tripStops: IPolyRouteEvent[] = this.tripDetailsData.stops.map<IPolyRouteEvent>(v => {
      const pre = {
        position: {
          lat: v.location.latitude,
          lng: v.location.longitude
        },
        options: {
          draggable: false,
          icon: `https://api.geoapify.com/v1/icon/?type=material&color=${'%23D2222D'}&textSize=small&text=${"VS"}&apiKey=8e47516235ac4f3a8f67f501b3453f4f`
        },
        meta: {
          type: 'stops',
          severity: severityTypes[this.getSeverity(1)],
          stop: v
        }
      }
      return pre
    })

    // const extraTripEvents: IPolyRouteEvent[] = Object.keys(EIncidentType).filter(key => key != "Trip Start" && key != "Trip Finish" && key != "All")
    //   .map<IPolyRouteEvent>(key => {
    //     const p = this.tripDetailsData.pathInfo[Math.floor(Math.random()*this.tripDetailsData.pathInfo.length)]
    //     const t: IPolyRouteEvent = {
    //       position: {
    //         lat: p.latitude,
    //         lng: p.longitude
    //       },
    //       options: {
    //         draggable: false,
    //         clickable: true,
    //         icon: getMapMarkerForEvent(EIncidentType[key], 1 + Math.floor(Math.random()*3)),
    //       }
    //     }
    //     return t
    //   })

    // console.log('extra', extraTripEvents)

    this.mapEventsData =  tripStartEvent && tripEndEvent
      ? [tripStartEvent, ...tripEvents, tripEndEvent]
    : null

    this.stopsMapEventsData =  tripStartEvent && tripEndEvent
      ? [tripStartEvent, ...tripStops, tripEndEvent]
      : null

    // console.log('markers', mapEvents)

    this.polyTrip$ = of(trip)
    this.polyMarkers$ = of(this.mapEventsData)
    this.stopsPolyMarkers$ = of(this.stopsMapEventsData)

    // set up the header...

    //const days = TODO
    const hours = ((this.tripDetailsData.duration / 3600) < 1) ? "00h" : `${Math.floor((this.tripDetailsData.duration / 3600)).toString().padStart(2, '0')}h`
    const mins = `${Math.round((this.tripDetailsData.duration % 3600) / 60).toString().padStart(2, '0')}m`
    // const seconds = TODO

    const header: IPolyTripHeaderData = {
      driverName: this.tripDetailsData.driverName,
      assetId: this.tripDetailsData.asset?.assetId,
      time: moment(this.tripDetailsData.startTime).format('DD-MM-YYYY / HH:mm:ss'),
      duration: `${hours}:${mins}`,
      distance: `${this.tripDetailsData.tripDistance?.toFixed(2)} mi`,
      incidentCount: (this.tripDetailsData.eventCount?.total || "0").toString()
    }

    this.polyHeader$ = of(header)

    this._rawPolyTableData = this.tripDetailsData.violations?.map<IPolyIncidentTableResult>(v => {
      console.log('table severity', severityTypes[this.getSeverity(v.eventIndex)])
      return {
        incident: `${EIncidentType[remapTypeIn(v.eventType)]} - ${moment(v.timestamp).format('HH:mm')}`,
        action: ["trip.details.play_video"],
        type: severityTypes[this.getSeverity(v.eventIndex)],
        meta: {
          violation: v
        }
      }
    })

    this.polyTableData$ = of(this._rawPolyTableData)
    this.tableDataLoaded$ = of(true)

    setTimeout(() => {
      this.bounds = new google.maps.LatLngBounds()
      trip.route?.locationData.forEach(b => this.bounds.extend(b))
      this.map.fitBounds(this.bounds)
      this.stopsMap.fitBounds(this.bounds)
    }, 1) // hack to let an update cycle pass so map is initialised
  }

  extractPageDataV2(): void {

    const severityTypes = ['low', 'medium' , 'high']
    const obj = this.tripDetailsData.pathInfo?.reduce((acc, location) => {
      if(!(location.latitude === 0 && location.longitude === 0)) {
        acc.route.locationData.push({
          lat: location.latitude,
          lng: location.longitude
        })
        acc.routeTimes.push(location.timestampUTC.toString())
      }
      return acc
    }, {route: {locationData: []}, routeTimes: []})
    const route:IPolyRoute = obj?.route
    const routeTimes = obj?.routeTimes || []
    let violations;
    try{
      violations = this.tripDetailsData?.driverviolations
      ? this.tripDetailsData?.driverviolations.map<IPolyViolation>(v => {
        return {
          type: EIncidentType[remapTypeIn(v.eventType)],
          location: {
            lat: v['location'].latitude,
            lng: v['location'].longitude,
          },
          time: new Date(v['location'].startTimeUTC).toString(),
          severity: v.severity
        }}).sort((a,b) => b.time.localeCompare(a.time))
      : null
    }catch(err){
      console.log('Error in extractPageDataV2:', err);
      violations = null;
    }
    

    //console.log('*TD: violations count', violations)
    //console.log('*TD: PathInfo count', this.tripDetailsData.pathInfo?.length || 0)
    const trip: IPolyTrip = {
      tripId: this.tripDetailsData.tripId,
      driverId: this.tripDetailsData.driverId,
      driverName: this.tripDetailsData.driverName,
      tripStart: this.tripDetailsData.startTime,
      tripEnd: this.tripDetailsData.startTime,
      route,
      routeTimes,
      violations
    }

    let tripStartEvent;
    try{
      tripStartEvent = this.tripDetailsData?.pathInfo ? {
        position: {
          lat: this.tripDetailsData.pathInfo[0].latitude,
          lng: this.tripDetailsData.pathInfo[0].longitude
        },
        options: {
          draggable: false,
          icon: getMapMarkerForEvent(ETripEventType['Trip-Start'])//?.iconURI,
        },
        meta: {
          type: 'ignition',
          action: 'on'
        }
      } : null
    }catch(err){
      console.log('Error in extractPageDataV2:', err);
      tripStartEvent = null;
    }
    

    let tripEndEvent;
    try{
      tripEndEvent = this.tripDetailsData?.pathInfo ? {
        position: {
          lat: this.tripDetailsData.pathInfo[this.tripDetailsData.pathInfo.length-1].latitude,
          lng: this.tripDetailsData.pathInfo[this.tripDetailsData.pathInfo.length-1].longitude
        },
        options: {
          draggable: false,
          icon: getMapMarkerForEvent(ETripEventType['Trip-Finish'])//?.iconURI,
        },
        meta: {
          type: 'ignition',
          action: 'off'
        }
      } : null
    }catch(err){
      console.log('Error in extractPageDataV2:', err);
      tripEndEvent = null;
    }
    
    let tripEvents: IPolyRouteEvent[];
    try{
      tripEvents = this.tripDetailsData.driverviolations.map<IPolyRouteEvent>(v => {
        console.log('v.eventType', this.translate.instant(EIncidentType[remapTypeIn(v.eventType)]))
        v.eventType = remapTypeIn(v.eventType)
        const pre = {
          position: {
            lat: v['location'].latitude,
            lng: v['location'].longitude
          },
          options: {
            draggable: false,
            icon: getMapMarkerForEvent( EIncidentType[remapTypeIn(v.eventType)], v.severity),//?.iconURI,
          },
          meta: {
            type: 'violation',
            violation: v,
            severity: severityTypes[this.getSeverity(v.eventIndex)]
          }
        }
        return pre
      })
    }catch(err){
      console.log('Error in extractPageDataV2:', err);
      tripEvents = null;
    }
   
    let extraTripEvents: IPolyRouteEvent[];
    try{
      extraTripEvents = Object.keys(EIncidentType).filter(key => key != "Trip Start" && key != "Trip Finish" && key != "All")
      .map<IPolyRouteEvent>(key => {
        const p = this.tripDetailsData.pathInfo[Math.floor(Math.random()*this.tripDetailsData.pathInfo.length)]
        const t: IPolyRouteEvent = {
          position: {
            lat: p.latitude,
            lng: p.longitude
          },
          options: {
            draggable: false,
            clickable: true,
            icon: getMapMarkerForEvent(EIncidentType[key], 1 + Math.floor(Math.random()*3)),
          }
        }
        return t
      })
    }catch(err){
      console.log('Error in extractPageDataV2:', err);
      extraTripEvents = null;
    }

    console.log('extra', extraTripEvents)

    this.mapEventsData =  tripStartEvent && tripEndEvent
      ? [tripStartEvent, tripEndEvent]
    : null

    this.mapEventsData =  tripStartEvent && tripEndEvent
      ? [tripStartEvent, ...tripEvents, tripEndEvent]
    : null

   // console.log('markers', mapEvents)

    this.polyTrip$ = of(trip)
    this.polyMarkers$ = of(this.mapEventsData)

    // set up the header...

    const hours = Math.floor( this.tripDetailsData.duration / 3600000); // 1 hour = 3600000 milliseconds
    const mins = Math.floor(( this.tripDetailsData.duration % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const formattedDuration = `${hours.toString().padStart(2, '0')}h ${mins.toString().padStart(2, '0')}m`;

    const header: IPolyTripHeaderData = {
      driverName: this.tripDetailsData.driverName,
      assetId: this.tripDetailsData.assetId,
      time: moment(this.tripDetailsData.startTimeUTC).format('DD-MM-YYYY / HH:mm:ss'),
      duration: formattedDuration,
      distance: `${this.tripDetailsData.distance?.toFixed(2)} km`,
      incidentCount: (this.tripDetailsData.eventCount?.total || "0").toString()
    }

    this.polyHeader$ = of(header)

    this._rawPolyTableData = this.tripDetailsData.driverviolations?.map<IPolyIncidentTableResult>(v => {
      console.log('table severity', severityTypes[this.getSeverity(v.eventIndex)])
      return {
        incident: `${EIncidentType[remapTypeIn(v.eventType)]} - ${moment(v["location"].timestampUTC).format('HH:mm')}`,
        action: ["trip.details.play_video"],
        type: severityTypes[v.severity],
        meta: {
          violation: v
        }
      }
    })

    this.polyTableData$ = of(this._rawPolyTableData)
    this.tableDataLoaded$ = of(true)

    setTimeout(() => {
      this.bounds = new google.maps.LatLngBounds()
      trip.route?.locationData.forEach(b => this.bounds.extend(b))
      this.map.fitBounds(this.bounds)
    }, 1) // hack to let an update cycle pass so map is initialised
  }

  markerClicked(polyRouteEvent: IPolyRouteEvent, marker: MapMarker): void {
    console.log('marker clicked', polyRouteEvent)
    switch(polyRouteEvent.meta?.type) {
      case 'ignition': {
        this.infoContent = polyRouteEvent.meta.action === 'on' ? `<p><b>${this.translate.instant('tripDetails.tripStart')}!</b></p>` : polyRouteEvent.meta.action ? `<p><b>${this.translate.instant('tripDetails.tripEnd')}!</b></p>` : '<p><b>Error!</b></p>'
        break
      }
      case 'violation': {
        this.infoContent = `<table class='trip-details-info-window-table'><tr><td class='trip-details-info-window-key'><p>${this.translate.instant('tripDetails.incident')}:</p></td><td><p><b>${polyRouteEvent.meta.violation.eventType}</b></p></td></tr><tr><td style="padding-right:1em;"><p>${this.translate.instant('tripDetails.time')}:</p></td><td><p><b>${moment(polyRouteEvent.meta.violation.timestamp).format('HH:mm:ss')}</b></p></td></tr></table>`
      }
      case 'stops': {
        this.infoContent = `<table class='trip-details-info-window-table'><tr><td class='trip-details-info-window-key'><p> ${this.translate.instant('stopsTable.stoppedAt')}:</p></td><td><p><b>${this.stopsTable.formatTime(polyRouteEvent.meta.stop.startTime)}</b></p></td></tr><tr><td style="padding-right:1em;"><p>${this.translate.instant('stopsTable.for')}:</p></td><td><p><b>${this.stopsTable.formatDuration(polyRouteEvent.meta.stop.duration)} ${this.translate.instant('stopsTable.mins')} </b></p></td></tr></table>`
      }
    }
    //this.infoContent = `<p>Incident type: ${polyRouteEvent.meta}`
    this.infoWindow.open(marker)
  }
}
