import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map, skipWhile } from 'rxjs/operators';
import { CognitoService } from '../services/cognito-service';
import { UserService } from '../services/user-service';

@Injectable()
export class AdminGuardService implements CanActivate {
  constructor(public authService: CognitoService, public userService: UserService, public router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    console.log('canActivate', route)
    return from(this.authService.getUser().then((u) => {
      return this.userService.getPortalUser(u.username)
    })).pipe(
      skipWhile((user) => user === null),
      map((user) => {
        if (!user.email.endsWith("@polysurance.com")) {
          this.router
            .navigate(['/'])
            .then(() => console.warn('Unable to activate the requested route: ' + route.url));
        }
        return true;
      })
    );
  }
}
