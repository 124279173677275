import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription, take, scan, of } from 'rxjs';
import { IFleetHighlight } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-highlights';
import { generateViolationOptions, IReqFleetViolations, IResFleetViolationsRow } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-violations';
import { EIncidentType, outgoingEventTypeMap, remapTypeIn } from 'src/app/concepts/incidents/incident-types';
import { getLabel as _getLabel, Incident } from 'src/app/concepts/incidents/incidents';
import { IPolyPaginationDigest } from 'src/app/concepts/pagination/pagination';
import { User } from 'src/app/concepts/user/user';
import { IDateRange } from 'src/app/concepts/utils/chrono-utils';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import videojs from 'video.js';
import { VideoPlayerDialogComponent } from './dialogs/video-player/video-player-dialog.component';
import * as _ from 'lodash';
import { VikingAPIService } from 'src/app/providers/services/viking-api-service';
import { TranslateService } from '@ngx-translate/core';
import { FleetService } from 'src/app/providers/services/fleet-service';

@Component({
  selector: 'vex-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChildren('highlightVideo') highlightVideos: QueryList<ElementRef>;
  public title: string
  public mode: "driver" | "asset"
  @Input() set setTitle(title:string) {
    this.title = title
    if(title.toLocaleLowerCase().includes('asset')) {
      this.mode = "asset"
    } else {
      this.mode = "driver"
    }
  }
  public driverId: string = undefined
  @Input() set setDriverId(id: string) {
    if(id) {
      console.log('setting driverId...', id, 'dateRange?', this._dateRange)
      this.driverId = id !== '0' ? id : undefined
      console.log('highlights, driverId', this.driverId)
      if(this._dateRange !== undefined) {
        console.log('calling runQuery from setDriverId')
        this._runQuery()
      }
      //this._runQuery()
    }
  }public assetId: string = undefined
  @Input() set setAssetId(id: string) {
    if(id) {
      console.log('setting assetId...', id, 'dateRange?', this._dateRange)
      this.assetId = id !== '0' ? id : undefined
      console.log('highlights, assetId', this.assetId)
      if(this._dateRange !== undefined) {
        console.log('calling runQuery from setAssetId')
        this._runQuery()
      }
    }
  }
  private _dateRange: IDateRange
  @Input() set dateRange(value: IDateRange) {
    if(value) {
      this._dateRange = value
      console.log('setting date range...', value, 'driverId?', this.driverId)
      if(this.driverId !== undefined || this.assetId !== undefined) {
        console.log('calling runQuery from dateRange')
        //this._runQuery()
      }
    }
    this._runQuery()
  }

  private firstRun = true

  player: videojs.Player[];

  public incidents: Incident[]
  public drivers: User[]
  public pageSize = 4
  public datasource$: Observable<IResFleetViolationsRow[]>

  private _subs: Subscription[] = []
  private _videoPlayerSub: Subscription
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  private _numResourcesToLoad: number = 1

  public violationsData: IResFleetViolationsRow[]
  public hightlightPanelValues$: Observable<IFleetHighlight[]>

  public incidentFilter: EIncidentType = this.translate.instant(EIncidentType.All)

  private _fleetType: boolean;
  user: string;

  public tippyProps = {
    theme: 'light'
  }

  public paginationDigest: IPolyPaginationDigest = {
    'highlights' : {
      entity: 'highlights',
      page: 0,
      pageSize: 4,
      limit: 4,
      length: 0,
      lastPage: false
    }
  }

  public violationsDataLoaded$: Observable<boolean>

  public language: string = this.translate.currentLang

  constructor(
    private fdl: FleetDataLayer,
    public dialog: MatDialog,
    public vapi: VikingAPIService,
    private translate: TranslateService,
    private fleetService: FleetService,
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
    // this.fleetService.setFleet()
    // .then(() => {
    //   this.fleetService.fleetType$.subscribe(async fleetType => {
    //     this._fleetType = fleetType;
    //     console.log('fleet type2', this._fleetType);
    //     //this._runQuery();
    //   });
    // });
  }

  private violationsDatasource = ((options: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> => {
    return this.fdl.getViolations(generateViolationOptions(options))
  });

  private violationsDatasourceV2 = ((options: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> => {
    return this.fdl.getViolationsV2(generateViolationOptions(options))

  });

  private driverViolationsDatasource = ((options: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> => {
    return this.fdl.getDriverViolations(this.driverId, generateViolationOptions(options))
  });

  private assetViolationsDatasource = ((options: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> => {
    return this.fdl.getAssetViolations(this.assetId, generateViolationOptions(options))
  });

  async ngOnInit(): Promise<void> {
    this.firstRun = false;
  }

  ngAfterViewInit(): void {
    this._videoPlayerSub = (this.highlightVideos.changes.subscribe(() => {
      this.player = this.highlightVideos.map(element => videojs(element.nativeElement, {'muted': true, 'controls': false}, () => console.log('onPlayerReady', this)))
    }))
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe())
    this._videoPlayerSub.unsubscribe()
  }

  private async _runQuery(): Promise<void> {
    console.log('pagination before', _.cloneDeep(this.paginationDigest))
    console.log('incidenttest', this.incidentFilter)
    this.violationsDataLoaded$ = of(false)

    this._numResourcesToLoad = 1

    if(this._subs?.length > 0) {
      this._subs.forEach(sub => sub.unsubscribe())
      this._subs = []
    }

    await this.fleetService.setFleet();
    const fleetType = await this.fleetService.fleetType$.pipe(take(1)).toPromise();
    this._fleetType = fleetType;
    console.log('fleet type2', this._fleetType);

    this._increment = new BehaviorSubject<number>(0);
    this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    this._subs.push(this.loaded.subscribe(loadedCounter => {
      if(loadedCounter === this._numResourcesToLoad) {
        this._extractHighlightsPanelData()
      }
    }))

    this._addHighlightsCache(this.paginationDigest.highlights.limit * this.paginationDigest.highlights.page,
      this.paginationDigest.highlights.limit + 1
    ) // Always request one more than we need to test for last on request

    this._getHighlightsResults(this.paginationDigest.highlights.limit * this.paginationDigest.highlights.page)
  }

  private _addHighlightsCache(skip: number, limit: number) {
    console.log('requesting', skip, limit)
    console.log('highlights, incidentFilter', this.incidentFilter)
    if(this.driverId) {
      this.fdl.addCache<IResFleetViolationsRow>('highlights.violations', this.driverViolationsDatasource, {
        after: this._dateRange.from,
        before: this._dateRange.to,
        limit,
        skip,
        eventType: this.incidentFilter === this.translate.instant('violations.all') ? null : this._getOutgoingEventType(this.incidentFilter),
        driverId: this.driverId
      }, true)
    } else if(this.assetId) {
      this.fdl.addCache<IResFleetViolationsRow>('highlights.violations', this.assetViolationsDatasource, {
        after: this._dateRange.from,
        before: this._dateRange.to,
        limit,
        skip,
        eventType:  this.incidentFilter === this.translate.instant('violations.all') ? null : this._getOutgoingEventType(this.incidentFilter),
        assetId: this.assetId
      }, true)
    } else {
          this.fdl.addCache<IResFleetViolationsRow>('highlights.violations', this.violationsDatasource, {
            after: this._dateRange.from,
            before: this._dateRange.to,
            limit,
            skip,
            eventType: this.incidentFilter === this.translate.instant('violations.all') ? null : this._getOutgoingEventType(this.incidentFilter)
          }, true)
    }
    console.log('highlights, incidentFilter', this.incidentFilter)
  }

  private _getHighlightsResults(skip: number) {
    if(this._fleetType){
      this.violationsDatasourceV2({
        after: this._dateRange.from,
        before: this._dateRange.to,
        skip,
        eventType: this.incidentFilter === this.translate.instant('violations.all') ? null : this._getOutgoingEventType(this.incidentFilter),
        challengeRaisedBefore: '',
        challengeRaisedAfter: '',
        challengeResolvedBefore: '',
        challengeResolvedAfter: '',
        sort: 'desc',
        division: '',
        bookmarkedOnly: '',
        tagSearch: '',
        challengeRaised: '',
        challengeResolved: '',
        limit: this.paginationDigest.highlights.limit + 1,
        driverId: this.driverId || '',
        assetId: this.assetId || '',
        sortBy: '' || 'timestamp'
      }).pipe(take(1))
      .subscribe(res => {
        this.paginationDigest.highlights.length = this.paginationDigest.highlights.page * this.paginationDigest.highlights.limit + (res?.length || 0)
        res = res?.length === 5 ? res.slice(0,-1) : res
        this.violationsData = res
        console.log('DRIVER/ASSETS HIGHLIGHTS RES V2', res)

        // check to see if we exhausted the data
        if(res?.length <= this.paginationDigest.highlights.limit) {
          this.paginationDigest.highlights.lastPage = true
        }
        this._increment.next(1)
      })
    }else{
      this.fdl.getCacheType<IResFleetViolationsRow>('highlights.violations')
    .getResults([this.assetId || this.driverId])
    .pipe(take(1))
    .subscribe(res => {
      this.paginationDigest.highlights.length = this.paginationDigest.highlights.page * this.paginationDigest.highlights.limit + (res?.length || 0)
      res = res?.length === 5 ? res.slice(0,-1) : res
      this.violationsData = res
      console.log('DRIVER/ASSETS HIGHLIGHTS RES', res)

      // check to see if we exhausted the data
      if(res?.length <= this.paginationDigest.highlights.limit) {
        this.paginationDigest.highlights.lastPage = true
      }
      this._increment.next(1)
    })
    }
  }

  private _getOutgoingEventType(incident: string) {
    return this.translate.instant(outgoingEventTypeMap[incident])
  }
  public onVideoLoad(event: any) {
    console.log('onVideoLoad', event)
  }

  public onIncidentFilter(event: any) {
    console.log('INCIDENT FILTER EVENT', event)
    this.incidentFilter = event === 'violations.all' ?  this.translate.instant(event) : EIncidentType[outgoingEventTypeMap[event]]
    console.log('INCIDENT FILTER RESULT', this.incidentFilter)
    console.log('calling RunQuery from onIncidentFilter')
    this._runQuery()
  }

  private _extractHighlightsPanelData() {
    if(this._fleetType){
      if(this.violationsData) {
        console.log('this.violationsData', this.violationsData)
        this.hightlightPanelValues$ = of(this.violationsData
          ?.map((row): IFleetHighlight => (
            {
              eventType: this.translate.instant(EIncidentType[remapTypeIn(row.eventType)]),
              eventIndex: row.eventIndex,
              driverId: row.driverId,
              driverName: row.driverName,
              time: (row.location && row.location.timestampUTC !== undefined) ? moment(row.location.timestampUTC).format('DD-MM-YYYY / HH:mm:ss') : '',
              videoUrl: ["Distracted-Driving"].includes(row.eventType) ? row.videos.back : row.videos.front,
              videoLoaded: false,
              tripId: row.tripId,
              position: {
                lat: (row.location && row.location.latitude !== undefined) ? row.location.latitude : 0.0,
                lng: (row.location && row.location.longitude !== undefined) ? row.location.longitude : 0.0
              },
              assetId: (row.asset && row.asset.assetId) ? row.cameraId : null
            }
          )));
      } else {
        this.hightlightPanelValues$ = of([])
      }
    }else{
      if(this.violationsData) {
        console.log('this.violationsData', this.violationsData)
        this.hightlightPanelValues$ = of(this.violationsData
          ?.map((row): IFleetHighlight => ({
            eventType: this.translate.instant(EIncidentType[remapTypeIn(row.eventType)]),
            eventIndex: row.eventIndex,
            driverId: row.driverId,
            driverName: row.driverName,
            time: moment(row.timestamp).format('DD-MM-YYYY / HH:mm:ss'),
            videoUrl: row.mediaFiles && row.mediaFiles.length > 0 ? row.mediaFiles[0].mediaFile : null,
            videoLoaded: false,
            tripId: row.tripId,
            position: {
              lat: row.latitude,
              lng: row.longitude
            },
            assetId: (row.asset && row.asset.assetId) ? row.asset.assetId : null
          })))
      } else {
        this.hightlightPanelValues$ = of([])
      }
    }
    this.violationsDataLoaded$ = of(true)
    this._increment.next(1)
  }

  public hasData(): boolean {
    return this.violationsData?.length > 0
  }

  // The reason I do this is to keep ALL conversions in one place
  public getLabel = (type: EIncidentType) : string => _getLabel(type)

  public counter(i: number) : number[] {
    return new Array(i);
  }

  public onHighlightsPaginationRequest(entity: string, paginationRequest: any) {
    switch(entity) {
      case 'highlights': {
        this.paginationDigest.highlights.page = paginationRequest.pageIndex
        break
      }
    }
    console.log('calling runquery from onHighlightsPaginationRequest')
    this._runQuery()
  }

  public openDialog(highlight : IFleetHighlight): void {
    console.log("CLICK VIDEO!!!")
    this.dialog.open(VideoPlayerDialogComponent, {
      width:'75%',
      maxWidth:'1000px',
      height:'680px',
      hasBackdrop: true,
      backdropClass: 'bdrop',
      data: highlight
    })
    ?.afterClosed().pipe(take(1)).pipe(take(1)).subscribe(result => {
      if(result.delete === true) {
        this.vapi.deleteViolation(highlight.driverId, highlight.tripId, highlight.eventIndex, highlight.eventType)
        this._runQuery()
      }
    });
  }


}


