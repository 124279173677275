import { EIncidentType } from "./incident-types"

export interface IIncident {
  id: string
  tripId: string
  type: string
  thumbURI: string
  videoURI: string
  date: string
  driverId: string
}

export class Incident implements IIncident {
  id: string
  tripId: string
  type: string
  thumbURI: string
  videoURI: string
  date: string
  driverId: string
  driverName?: string

  public name = (): string => EIncidentType[this.type]
}

export interface IIncidentTypePickerData {
  [key: string]: EIncidentType
}

const incidentPickerData: IIncidentTypePickerData = {
  'All': EIncidentType.All,
  'Distracted-Driving': EIncidentType['Distracted-Driving'],
  'Forward-Collision-Warning': EIncidentType['Forward-Collision-Warning'],
  'Harsh-Acceleration': EIncidentType['Harsh-Acceleration'],
  'Harsh-Braking': EIncidentType['Harsh-Braking'],
  'Harsh-Cornering': EIncidentType['Harsh-Cornering'],
  'Speed-Limit-Violation': EIncidentType['Speed-Limit-Violation'],
  'Stop-Sign-Violation': EIncidentType['Stop-Sign-Violation'],
  'Tailgating': EIncidentType['Tailgating']
}

export const getIncidentPickerData = () : IIncidentTypePickerData => Object.keys(EIncidentType).reduce((acc, key) => acc = {...acc, [key]:EIncidentType[key]}, {})

export const getLabelData = () : string[] => {
  return Object.keys(EIncidentType).map(k => {
    return EIncidentType[k]
  })
}

export const getLabel = (type: EIncidentType|string): string => {
  return EIncidentType[type]
}

export const getIncidentTypesAsKeys = () => Object.keys(EIncidentType).filter(x => !(parseInt(x) >= 0))
export const getIncidentTypeFromString = (i:string) :EIncidentType => EIncidentType[Object.keys(EIncidentType).find(e => EIncidentType[e] === i)]

