import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, scan, Observable, of, take, tap } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { generateDriversOptions, IReqFleetDrivers, IResFleetDriversRow, ITopDrivers } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-fleet-drivers';
import { IResFleetViolationsRow } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-violations';
import { ITrip } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-fleet-trips';
import { TopDriverResult } from 'src/app/concepts/user/driver/driver';
import { IDateRange } from 'src/app/concepts/utils/chrono-utils';
import { FleetDataLayer, ICacheOptions } from 'src/app/providers/data-layers/fleet-data-layer';
import { IReqAggregateDriverTrips, IResAggregateDriverTripsRow, generateAggregateDriverTripsOptions, IResAggregateDriverTripsRowValue } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-aggregate-trips';
import { IPolyPaginationDigest } from 'src/app/concepts/pagination/pagination';
import { Console } from 'console';
import { TranslateService } from '@ngx-translate/core';


export interface IAggregateDataDigest {
  [key: string] : IResAggregateDriverTripsRowValue
}
@Component({
  selector: 'vex-top-drivers',
  templateUrl: './top-drivers.component.html',
  styleUrls: ['./top-drivers.component.scss']
})
export class TopDriversComponent implements OnInit, OnDestroy {

  public tableColumns: TableColumn<TopDriverResult>[] = [
    {
      label: this.translate.instant('tripDetails.driver'),
      property: 'name',
      type: 'text'
    },
    {
      label: this.translate.instant('trips.distance'),
      property: 'distance',
      type: 'text'
    },
    {
      label: this.translate.instant('trips.incidentsper100km'),
      property: 'incidents',
      type: 'text',
      cssClasses: ['font-medium']
    },
    {
      label: this.translate.instant('shortNames.Distracted'),
      property: 'Distracted',
      type: 'text'
    },
    {
      label: this.translate.instant('shortNames.Cornering'),
      property: 'Cornering',
      type: 'text'
    },
    // {
    //   label: this.translate.instant('shortNames.Drowsy'),
    //   property: 'Drowsy_Driving',
    //   type: 'text'
    // },
    {
      label: this.translate.instant('shortNames.FwdCollision'),
      property: 'Forward_Collision_Warning',
      type: 'text'
    },
    {
      label: this.translate.instant('shortNames.Acceleration'),
      property: 'Harsh_Acceleration',
      type: 'text'
    },
    {
      label: this.translate.instant('shortNames.Braking'),
      property: 'Harsh_Braking',
      type: 'text'
    },
    // {
    //   label: this.translate.instant('shortNames.LaneDrift'),
    //   property: 'Lane_Drift',
    //   type: 'text'
    // },
    // {
    //   label: this.translate.instant('shortNames.MaxSpeed'),
    //   property: 'MaxSpeedExceeded',
    //   type: 'text'
    // },
    {
      label: this.translate.instant('shortNames.SeatBelt'),
      property: 'SeatBelt_Non_Compliance',
      type: 'text'
    },
    {
      label: this.translate.instant('shortNames.Tailgating'),
      property: 'Tail_Gating',
      type: 'text'
    },
    {
      label: this.translate.instant('shortNames.StopSign'),
      property: 'STOP_Sign_Violated',
      type: 'text'
    },
    {
      label: this.translate.instant('shortNames.SpeedViolation'),
      property: 'Traffic_Speed_Violated',
      type: 'text'
    }
  ];

  private _dateRange: IDateRange
  @Input() set dateRange(value: IDateRange) {
    this._dateRange = value
    this._runQuery()
  }

  private _subs: Subscription[] = []
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  private _numResourcesToLoad: number = 1
  public driversData: IResFleetDriversRow[] // TODO - I want to pass this from the Behavior Events, or request it from FDL, no sense in having two copies!
  public aggregateData: IAggregateDataDigest = {}
  public driversTableData$ : Observable<ITopDrivers[]>
  public tripData: ITrip[]
  public violationsData: IResFleetViolationsRow[]
  public tableDataLoaded$: Observable<Boolean> = of(new Boolean(false))
  public language: string = this.translate.currentLang;

  public paginationDigest: IPolyPaginationDigest = {
    'topDrivers' : {
      entity: 'topDrivers',
      page: 0,
      pageSize: 5,
      limit: 5,
      length: 0,
      lastPage: false
    }
  }

  constructor(
    public fdl: FleetDataLayer,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  private driversDatasource = ((options: IReqFleetDrivers) : Observable<IResFleetDriversRow[]> => {
    return this.fdl.getDrivers(generateDriversOptions(options))
  });

  private driverTripsAggregateDatasource = ((options: IReqAggregateDriverTrips, driverId: string): Observable<IResAggregateDriverTripsRow> => {
    return this.fdl.getAggregateDriverTrips(driverId, generateAggregateDriverTripsOptions(options))
  })

  ngOnInit(): void {
  }

  debug(){
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe())
  }

  public onPageEvent(event: any) {
    this._runQuery(event.pageIndex * 4)
  }

  private _runQuery(skip: number = 0): void {
    this.tableDataLoaded$ = of(new Boolean(false))
    this.driversTableData$ = of([])
    this._numResourcesToLoad = 1

    if(this._subs?.length > 0) {
      this._subs.forEach(sub => sub.unsubscribe())
      this._subs = []
    }

    this._increment = new BehaviorSubject<number>(0);
    this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    this._subs.push(this.loaded.subscribe(loadedCounter => {
      if(loadedCounter === this._numResourcesToLoad) {
        this._extractDriversPanelData()
      }
    }))

    this.fdl.addCache<IResAggregateDriverTripsRow>('drivers.aggregate', this.driverTripsAggregateDatasource, {
      after: this._dateRange.from,
      before: this._dateRange.to,
      limit: 400,
      skip: 0,
      groupBy: null
    }, true)

    this.fdl.addCache<IResFleetDriversRow>('drivers', this.driversDatasource, {
      after: this._dateRange.from,
      before: this._dateRange.to,
      limit: this.paginationDigest.topDrivers.limit + 1, // Always request one more than we need to test for last on request
      skip: this.paginationDigest.topDrivers.limit * this.paginationDigest.topDrivers.page,
    }, true)

    this.fdl.getCacheType<IResFleetDriversRow>('drivers')
      .getPage(0)
      .pipe(take(1))
      .subscribe(res => {
        this.driversData = res
        this._numResourcesToLoad += res.length
        res.forEach(driver => {
          this.fdl.getCacheType<IResAggregateDriverTripsRowValue>('drivers.aggregate')
          .getResult([driver.driverId])
          .pipe(take(1))
          .subscribe(agg => {

            this.paginationDigest.topDrivers.length = this.paginationDigest.topDrivers.page * this.paginationDigest.topDrivers.limit + (res?.length || 0)

            this.aggregateData[driver.driverId] = agg
            this._increment.next(1)
          })
        }, true)
        this._increment.next(1)
    })

  }


  private _extractDriversPanelData() {
    const res = this.driversData.map(driver => {
      // console.log('**DRIVERS** driver data', driver)
      const driverTrips = this.aggregateData[driver.driverId]?.tripCount || 0
      const numViolations = this.aggregateData[driver.driverId]?.eventCount.total || 0
      const totalDistance = Math.round(this.aggregateData[driver.driverId]?.tripDistance || 0)
      console.log('numViolations', numViolations, 'totalDistance', totalDistance, 'res', (numViolations/totalDistance*100.0))
      console.log('aggregateData', this.aggregateData)
      return {
        name: driver.driverName || '(Unknown)',
        incidents: parseFloat(((numViolations || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        distance: totalDistance.toLocaleString('en-US'),
        Distracted: parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Cellphone-Distracted-Driving'] || 0 + this.aggregateData[driver.driverId]?.eventCount['Distracted-Driving'] || 0 + 
                    this.aggregateData[driver.driverId]?.eventCount['Drinking-Distracted-Driving'] || 0 + this.aggregateData[driver.driverId]?.eventCount['Lizard-Eye-Distracted-Driving'] || 0 + 
                    this.aggregateData[driver.driverId]?.eventCount['Smoking-Distracted-Driving'] || 0 + this.aggregateData[driver.driverId]?.eventCount['Drowsy-Driving-Detected'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        Cornering:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Cornering'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        //Drowsy_Driving: this.aggregateData[driver.driverId]?.eventCount['Drowsy-Driving-Detected'] || 0,
        Forward_Collision_Warning:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Forward-Collision-Warning'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        Harsh_Acceleration:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Harsh-Acceleration'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        Harsh_Braking:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Harsh-Braking'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
       // Lane_Drift:  Math.round((((this.aggregateData[driver.driverId]?.eventCount['Lane-Drift-Found'] || 0) || 0) / (totalDistance || 1) * 100.0)).toFixed(1).toString(),
       // MaxSpeedExceeded: this.aggregateData[driver.driverId]?.eventCount['MaxSpeedExceeded'] || 0,
        SeatBelt_Non_Compliance:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['SeatBelt-Non-Compliance'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        Tail_Gating: parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Tail-Gating-Detected'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        STOP_Sign_Violated:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Traffic-STOP-Sign-Violated'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString(),
        Traffic_Speed_Violated:  parseFloat((((this.aggregateData[driver.driverId]?.eventCount['Traffic-Speed-Violated'] || 0 + this.aggregateData[driver.driverId]?.eventCount['MaxSpeedExceeded'] || 0) || 0) / (totalDistance || 1) * 100.0).toFixed(1)).toString()
      }
    })
    const data = res.sort((a,b) => parseInt(a.incidents) - parseInt(b.incidents))
      .slice(
        this.paginationDigest.topDrivers.pageSize * this.paginationDigest.topDrivers.page,
        this.paginationDigest.topDrivers.pageSize * this.paginationDigest.topDrivers.page +
          this.paginationDigest.topDrivers.pageSize
      )
    this.driversTableData$ = of(data)
    if(this.paginationDigest.topDrivers.page * this.paginationDigest.topDrivers.pageSize > data.length) {
      this.paginationDigest.topDrivers.lastPage = true
    }
    //this._increment.next(1)
    this.tableDataLoaded$ = of(new Boolean(true))
  }

  public onTopDriversPaginationRequest(entity: string, paginationRequest: any) {
    this.tableDataLoaded$ = of(new Boolean(false))

    switch(entity) {
      case 'topDrivers': {
        this.paginationDigest.topDrivers.page = paginationRequest.page
        break
      }
    }
    // this._runQuery()
    this._extractDriversPanelData()
  }

}
