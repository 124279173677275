import { EIncidentType, ETripEventType, outgoingEventTypeMap, remapTypeIn } from "../incidents/incident-types"

export enum EAssetSource {
  assets = 0,
  http
}

export interface IMapMarker {
  label: string
  iconSource: EAssetSource
  iconURI: string
}

export interface IMapMarkerCollection {
  [key: string]: IMapMarker
}

export interface IMapPinConfig {
  color: string[]
  icon: string
}

export interface IMapPinLookup {
  [key: string]: IMapPinConfig
}

const violationColors = ['%23238823','%23FFBF00','%23D2222D']


export const mapPinOptions = {
  'Trip Start': { color: ['%23000000'], icon: 'key'},
  'Trip Finish': { color: ['%23000000'], icon: 'flag'},
  'violations.CellphoneDistractedDriving': { color: violationColors, text: 'MO'},
  // 'Cornering': { color: violationColors, text: 'CO'},
  'violations.DistractedDriving': { color: violationColors, text: 'DD'},
  'violations.DrowsyDrivingDetected': { color: violationColors, text: 'Zz'},
  'violations.ForwardCollisionWarning': { color: violationColors, text: 'FC'},
  'violations.HarshAcceleration': { color: violationColors, text: 'HA'},
  'violations.HarshBraking': { color: violationColors, text: 'HB'},
  'violations.HarshCornering': { color: violationColors, text: 'HC'},
  'violations.LaneDriftFound': { color: violationColors, text: 'LD'},
  'violations.MaxSpeedExceeded': { color: violationColors, text: 'MS'},
  'violations.SpeedLimitViolation': { color: violationColors, text: 'SP'},
  'violations.StopSignViolation': { color: violationColors, text: 'ST'},
  'violations.Tailgating': { color: violationColors, text: 'TG'},
  'violations.TrafficSpeedViolated' : { color: violationColors, text: 'SV'},
  'violations.SeatBeltViolation' : { color: violationColors, text: 'SB'},
  'violations.RollOverDetected' : { color: violationColors, text: 'RO'},
}

const mapMarkers : IMapMarkerCollection = {
  tripStart: {
    label: "Trip Start",
    iconSource: EAssetSource.http,
    iconURI: "https://api.geoapify.com/v1/icon/?type=material&color=%231acd06&iconType=awesome&textSize=small&icon=key&apiKey=8e47516235ac4f3a8f67f501b3453f4f"
  },
  tripEnd: {
    label: "Trip Finish",
    iconSource: EAssetSource.http,
    iconURI: "https://api.geoapify.com/v1/icon/?type=material&color=%23ff0000&iconType=awesome&textSize=small&icon=key&apiKey=8e47516235ac4f3a8f67f501b3453f4f"
  }
}

export const getMapMarkerForEvent = (event: EIncidentType | ETripEventType, severity: number = 1): string/*IMapMarker*/ => {
  if(event === ETripEventType['Trip-Start']) {
    return "https://api.geoapify.com/v1/icon/?type=material&color=%23424242&iconType=awesome&textSize=small&icon=key&apiKey=8e47516235ac4f3a8f67f501b3453f4f"
  }

  if(event === ETripEventType['Trip-Finish']) {
    return "https://api.geoapify.com/v1/icon/?type=material&color=%23424242&iconType=awesome&textSize=small&icon=parking&apiKey=8e47516235ac4f3a8f67f501b3453f4f"
  }

  const key = event//outgoingEventTypeMap[event]
  console.log('event', event, 'key', key, mapPinOptions[key])
  const {color, icon, text} = mapPinOptions[key]
  const url = icon
    ? `https://api.geoapify.com/v1/icon/?type=material&color=${color[(severity)] || '%23238823'}&iconType=awesome&textSize=small&icon=${icon}&apiKey=8e47516235ac4f3a8f67f501b3453f4f`
    : `https://api.geoapify.com/v1/icon/?type=material&color=${color[(severity)] || '%23238823'}&textSize=small&text=${text}&apiKey=8e47516235ac4f3a8f67f501b3453f4f`
  return url
}
