import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { VikingAPIService } from 'src/app/providers/services/viking-api-service';
import { UserService } from 'src/app/providers/services/user-service';
import { NavigationStart, Router } from '@angular/router';
import { FleetService } from 'src/app/providers/services/fleet-service';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  currentLanguage: string = this.lanuageToFlag(this.translate.currentLang);
  reload$ = new Subject<boolean>();


  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);

  public fleetIdLabels: string[];
  public selectedFleetId: string;
  public user: string = ''



  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              private translate: TranslateService,
              private vapi: VikingAPIService,
              private _userService: UserService,
              private router: Router,
              private fleetService: FleetService
              ) { }

  ngOnInit(): void {
    this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((event: NavigationStart) => {
      if (event.url === '/login') {
        this.user = '';
      }else if (event.url === '/') {
        this.getUser();
        this._userService.getPortalUser(this.user).then((user) => {
          this.selectedFleetId  = user.fleetId;
          });
      }
    });
    this.getUser();
    this._userService.getPortalUser(this.user).then((user) => {
      this.selectedFleetId  = user.fleetId;
      });
       
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  changeLanguage(lang: string): void {
    this.translate.use(lang);
    sessionStorage.setItem('language', lang);
    if(lang == 'en') {
      this.currentLanguage = 'en';
    }else if(lang == 'ar-AE') {
      this.currentLanguage = 'ksa';
  }
  // let styleName;
  // if(this.currentLanguage == 'ksa') {
  //   styleName = `_style-dfault-ar.scss`;
  // }else {
  //   styleName = `_style-dfault.scss`;
  // }
  // sessionStorage.setItem('style', styleName);
  window.location.reload();
}

lanuageToFlag(lang: string): string {
  if(lang == 'en') {
    return 'united-kingdom';
  }else if(lang == 'ar-AE') {
    return 'ksa';
}
}

public async onFleetChange(fleetId: string) {
  this.selectedFleetId = fleetId
  await this.vapi.setFleetId(fleetId)
  window.location.reload();
}

public getUser(){
  const localStorageKeys = Object.keys(localStorage);
  console.log('Local storage keys:', localStorageKeys);
  let lastAuthUser = null; 
  for (let i = 0; i < localStorageKeys.length; i++) {
    const key = localStorageKeys[i];
    if (key.endsWith('LastAuthUser')) {
      lastAuthUser = localStorage.getItem(key); 
      break;
    }
  }
  if (lastAuthUser) {
    console.log('Last authenticated user:', lastAuthUser);
    this.user = lastAuthUser
  }
  else
  { console.log('No last authenticated user found in local storage.'); }
}

}
