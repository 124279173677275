<div class="top-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start" fxFullWidth>
  <div fxFlex="100" class="section-card">
    <div class="section-container" fxFlex fxLayout="column" fxFlexLayout="space-between">
      <div fxFlex class="section-header" fxLayout="row" fxLayoutAlign="space-between start" fxFullWidth>
        <h3 class="title card-title">{{title}}</h3>
        <vex-incident-selector (change)="onIncidentFilter($event)"></vex-incident-selector>
      </div>
      <mat-card fxLayout="column" fxLayoutGap="20px">
        <div fxFlex class="section-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
          fxLayoutGap="40px" fxLayoutGap.lt-md="20px" fxFullWidth>
          <ng-container *ngIf="violationsDataLoaded$ | async; else loading">
            <ng-container *ngIf="hasData(); else noData">
              <div fxFlex fxLayout="column" class="incident-info" *ngFor="let incident of hightlightPanelValues$ | async">
                <!-- <img [src]="incident.thumbURI" class="cover"> -->
                <div class="video-container">
                  <video [style.cursor]="'pointer'" (click)="openDialog(incident)" #highlightVideo muted fill="true" preload="metadata" (load)="onVideoLoad($event)" class="cover video-thumbnail" >
                    <source type="video/mp4"
                      [src]="incident.videoUrl" >
                    <div class="video-cover"></div>
                  </video>
                  <div class="video-container-layer" (click)="openDialog(incident)">

                  </div>
                </div>
                <!-- <app-vjs-player [style.object-fit]="'contain'" class="cover video-thumbnail" [options]="{fluid: false, aspectRatio: '16:9', autoplay: false, sources: [{ src: incident.videoUrl, type: 'video/mp4' }]}"></app-vjs-player> -->
                <div fxFlex class="trip-details-top" fxLayout="row" fxFullWidth>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" fxFlex="75"
                    class="trip-details-top-left">
                    <p placement="top" class="trip-details-incident-type">{{ incident.eventType | translate}}</p>
                    <ng-template #personcard>
                      <div fxLayout="column" class="person-card" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                          <div fxFlexAlign="start">{{ 'highlights.driverName' | translate}}</div>
                          <div class="bold" fxFlexAlign="end">{{incident.driverName}}</div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                          <div fxFlexAlign="start">{{ 'highlights.driverId' | translate}}</div>
                          <div class="bold" fxFlexAlign="end">{{incident.driverId}}</div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="trip-details-top-right" fxLayout="row-reverse" fxFlex="25">
                    <mat-icon [ngxTippy]=" 'actionCollection.Tripdetails' | translate" [tippyProps]="tippyProps" [style.transform]="'scale(0.8)'" [routerLink]="['/trip-details/', incident.driverId, incident.tripId]" role="img" color="accent"
                      class="mat-icon notranslate mat-accent material-icons cursor-pointer" data-mat-icon-type="font">launch
                    </mat-icon>
                    <mat-icon [style.transform]="'scale(0.8)'" [ngxTippy]="personcard" [tippyProps]="tippyProps" role="img"
                      class="person-icon mat-icon notranslate material-icons" data-mat-icon-type="font">person</mat-icon>
                  </div>
                </div>
                <div fxFlex class="trip-details-bottom" fxLayout="row" fxFullWidth>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="75"
                    class="trip-details-top-left">
                    <p class="trip-details-incident-type">{{incident.time}}</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noData>
              <div class="no-data" fxFlexLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-icon role="img" data-mat-icon-type="font" class="mat-icon notranslate alert-icon material-icons mat-icon-no-color">info_outline</mat-icon>
                <span>{{ 'highlights.noData' | translate}}</span>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #loading>
            <div fxFlex fxLayout="column" class="incident-info" *ngFor="let in of counter(4) ;let i = index">
              <!-- <img [src]="incident.thumbURI" class="cover"> -->
              <div class="photo-loader">
                <content-loader fxFlex fxFlexAlign="center center" viewBox="0 0 100 56" [backgroundOpacity]="0" [foregroundOpacity]="0.1" >
                  <svg:rect x="0" y="0" width="100" height="56"  preserveAspectRatio="none" />
                </content-loader>
              </div>
              <div fxFlex class="trip-details-top" fxLayout="row" fxFullWidth>
                <div fxFlex [style.marginTop]="'5px'">
                  <content-loader fxFlex fxFlexAlign="center center" viewBox="0 0 100 10" [backgroundOpacity]="0" [foregroundOpacity]="0.1">
                    <svg:rect x="0" y="0" width="100" height="10"  preserveAspectRatio="none" />
                  </content-loader>
                </div>
              </div>
              <div fxFlex class="trip-details-bottom" fxLayout="row" fxFullWidth>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="75"
                  class="trip-details-top-left">
                  <p></p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div fxFlex class="highlights-content" fxLayout="row" fxLayoutAlign="end" fxFullWidth>
          <mat-paginator
            [style.opacity]="(violationsDataLoaded$ | async) ? '1' : '0'"
            [pageSize]="paginationDigest?.highlights?.pageSize || 0"
            [length]="paginationDigest?.highlights?.length || 0"
            (page)="onHighlightsPaginationRequest('highlights', $event)"
            class="paginator">
          </mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>
</div>
